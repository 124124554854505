import { createWebHistory, createRouter } from 'vue-router';
import Product from '@/views/Product.vue';
import ErrorCatchAll from '@/views/Error.vue';
import Shipping from '@/views/Shipping.vue';
import BraintreeCheckout from '@/views/braintree/Checkout.vue';
import StripeCheckout from '@/views/stripe/Checkout.vue';
import AdyenCheckout from '@/views/adyen/Checkout.vue';
import Thankyou from '@/views/Thankyou.vue';
import { elementScrollIntoView } from 'seamless-scroll-polyfill';

const routes = [
  {
    path: '/:product',
    name: 'Product',
    component: Product,
    meta: { gtm: 'Product Page' },
  },
  {
    path: '/shipping/:product',
    name: 'Shipping',
    component: Shipping,
    meta: { gtm: 'Shipping Page' },
  },
  {
    path: '/braintree/checkout/:product',
    name: 'BraintreeCheckout',
    component: BraintreeCheckout,
    meta: { gtm: 'Braintree Checkout Page' },
  },
  {
    path: '/stripe/checkout/:product',
    name: 'StripeCheckout',
    component: StripeCheckout,
    meta: { gtm: 'Stripe Checkout Page' },
  },
  {
    path: '/adyen/checkout/:product',
    name: 'AdyenCheckout',
    component: AdyenCheckout,
    meta: { gtm: 'Adyen Checkout Page' },
  },
  {
    path: '/error/:product?',
    name: 'Error',
    component: ErrorCatchAll,
    meta: { gtm: 'Error Catch All' },
  },
  {
    path: '/thankyou/:product',
    name: 'Thankyou',
    component: Thankyou,
    meta: { gtm: 'Thankyou Page' },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    const element = document.getElementById('app');
    if (element) return elementScrollIntoView(element, { behavior: 'smooth' });
  },
});

export default router;
