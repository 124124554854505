<template>
  <div id="payment-buttons-container">
    <div
      v-show="isPaymentButtonsInitialised"
      :class="{
        'is-visible-just-for-unit-test': isPaymentButtonsInitialised,
      }"
      data-test="payment-buttons-is-initialised-wrapper"
    >
      <div v-if="transaction.token" data-test="payment-buttons-is-token-set-wrapper">
        <!-- Adyen fields-->
        <div v-if="paymentProvider === 'adyen'" data-test="payment-buttons-is-adyen-wrapper">
          <AdyenFields isShowExpressCheckout :adyenSdk="adyenSdk" />

          <CardPaymentSelectionButton :onClick="progressToAdyenCardPaymentFlow" />
        </div>

        <!-- Stripe fields-->
        <div v-if="paymentProvider === 'stripe'" data-test="payment-buttons-is-stripe-wrapper">
          <!-- TODO disable card option here -->
          <StripeFields isShowExpressCheckout :doLoadStripe="loadStripe" />

          <CardPaymentSelectionButton :onClick="progressToStripeCardPaymentFlow" />
        </div>

        <!-- Braintree fields-->
        <div
          v-if="paymentProvider === 'braintree'"
          data-test="payment-buttons-is-braintree-wrapper"
        >
          <BraintreeApplePay
            v-if="supports.applePay"
            v-show="isPaymentButtonInitialisedSuccessfully('apple')"
            data-test="pay-btn-apple-pay"
          />
          <BraintreeGooglePay
            :googleApi="googleApi"
            :braintreeApi="braintreeApi"
            v-show="isPaymentButtonInitialisedSuccessfully('google')"
          />
          <BraintreePaypal
            :paypalApi="paypalApi"
            :braintreeApi="braintreeApi"
            v-show="isPaymentButtonInitialisedSuccessfully('paypal')"
          />

          <div v-if="isPaymentButtonInitialisedSuccessfully('hostedFields')">
            <CardPaymentSelectionButton :onClick="progressToBraintreeCardPaymentFlow" />
          </div>
        </div>
      </div>
    </div>

    <div v-show="!isPaymentButtonsInitialised" class="loading-message-container">
      <lottie-animation :animationData="loaderAnimation" :loop="true" />

      <span>Loading payment options...</span>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';

import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { useMainStore } from '@/stores/MainStore.ts';
import { usePaymentStore } from '@/stores/PaymentStore';
import { usePublisherStore } from '@/stores/PublisherStore.js';

import BraintreeGooglePay from '@/components/Payment/Braintree/BraintreeGooglePay/index.vue';
import BraintreeApplePay from '@/components/Payment/Braintree/BraintreeApplePay/index.vue';
import BraintreePaypal from '@/components/Payment/Braintree/BraintreePaypal/index.vue';
import AdyenFields from '@/components/Payment/Adyen/AdyenFields.vue';
import StripeFields from '@/components/Payment/Stripe/StripeFields.vue';
// //// import AdyenGooglePay from '@/components/Payment/Adyen/AdyenGooglePay/index.vue';
import CardPaymentSelectionButton from '@/components/Payment/CardPaymentSelectionButton.vue';

import loader from '@/assets/loader.json';

import { getRouter } from '@/router';
import constants from '@/constants';

export default {
  name: 'PaymentComponent',
  components: {
    BraintreeGooglePay,
    BraintreeApplePay,
    BraintreePaypal,
    AdyenFields,
    StripeFields,
    CardPaymentSelectionButton,
  },
  props: {
    loadStripe: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      open: false,
      googleApi: window.google,
      paypalApi: window.paypal,
      braintreeApi: window.braintree,
      adyenSdk: window.AdyenWeb,
      paymentButtonInitialisationTimerId: null,
      paymentButtonInitialisationTimerExpired: false,
    };
  },
  mounted() {
    this.startPaymentButtonInitialisationTimer();
  },
  beforeUnmount() {
    if (this.paymentButtonInitialisationTimerId) {
      clearTimeout(this.paymentButtonInitialisationTimerId);
    }
  },
  computed: {
    ...mapState(useAdvertiserStore, ['companyName', 'advertiserTerms', 'paymentProvider']),
    ...mapState(usePaymentStore, ['transaction']),
    ...mapState(usePublisherStore, ['publisherUrl']),
    ...mapState(useMainStore, [
      'supports',
      'isPaymentButtonsInitialised',
      'initialisedPaymentButtons',
    ]),

    loaderAnimation() {
      return loader;
    },
  },
  methods: {
    ...mapActions(useMainStore, [
      'redirectToView',
      'assessAvailablePaymentButtonsAfterInitialisationAttempted',
    ]),

    async progressToBraintreeCardPaymentFlow() {
      const router = await getRouter();
      this.redirectToView('/shipping', router.push);
    },

    async progressToStripeCardPaymentFlow() {
      const router = await getRouter();
      this.redirectToView('/stripe/checkout', router.push);
    },

    async progressToAdyenCardPaymentFlow() {
      const router = await getRouter();
      this.redirectToView('/shipping', router.push);
    },

    /**
     * Wait a finite amount of time before deciding to conditionally render payment buttons based on whether they successfully initialised in
     * an arbritray but reasonable amount of time. I.e. upon this timeout, then only render buttons which initialised, or rather don't let one failed
     * button prevent any of them from showing.
     */
    startPaymentButtonInitialisationTimer() {
      this.paymentButtonInitialisationTimerId = setTimeout(() => {
        this.assessAvailablePaymentButtonsAfterInitialisationAttempted(this.paymentProvider);
        this.paymentButtonInitialisationTimerExpired = true;
      }, constants.PAYMENT_BTN_INITIALISATION_CHECK_TIMEOUT);
    },

    isPaymentButtonInitialisedSuccessfully(flag) {
      return !!this.initialisedPaymentButtons[this.paymentProvider][flag];
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
