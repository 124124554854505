<template>
  <button
    aria-label="Credit or Debit Card"
    class="button button--primary button--full-width card-payment border-rounded-small"
    @click="onClick"
    type="button"
    data-test="hosted-fields-payment-btn"
  >
    Pay with Debit or Credit Card
  </button>
  <div class="payment-icons">
    <IconVisa />
    <IconMastercard />
    <IconAmericanExpress />
    <IconMaestro />
  </div>
</template>

<script>
import IconVisa from '@/components/icons/IconVisa.vue';
import IconAmericanExpress from '@/components/icons/IconAmericanExpress.vue';
import IconMastercard from '@/components/icons/IconMastercard.vue';
import IconMaestro from '@/components/icons/IconMaestro.vue';

export default {
  name: 'CardPaymentSelectionButton',
  components: { IconVisa, IconMastercard, IconAmericanExpress, IconMaestro },
  props: {
    onClick: Function,
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
