<template>
  <div v-if="isRenderComponent" class="paypal-container">
    <div id="paypal-button"></div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useMainStore } from '@/stores/MainStore.ts';
import { usePaymentStore } from '@/stores/PaymentStore';
import { useShippingStore } from '@/stores/ShippingStore.ts';
import { useCartStore } from '@/stores/CartStore.ts';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { isBraintreePaymentAvailable } from '@/helpers/braintreePayments.ts';
import gtmTracker from '@/helpers/googleTagManager.ts';
import { PayPalPaymentError } from '@/types/errors.types.ts';

export default {
  name: 'BraintreePayPal',
  props: {
    paypalApi: {},
    braintreeApi: {},
  },
  data() {
    return {
      actions: null,
      shippingOption: null,
      paypalActions: null,
      isRenderComponent: true,
    };
  },
  computed: {
    ...mapState(useMainStore, ['currency']),
    ...mapState(usePaymentStore, ['transaction']),
    ...mapState(useShippingStore, ['address', 'shippingMethods']),
    ...mapState(useCartStore, ['getTotalCartPrice', 'getTotalPaymentPrice']),
    ...mapState(useAdvertiserStore, ['paymentProvider']),
  },
  unmounted() {
    const button = document.getElementById('paypal-button');

    if (button) return (document.getElementById('paypal-button').innerHTML = '');
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(usePaymentStore, ['setBraintreePaymentData', 'placeBraintreeOrder']),
    ...mapActions(useShippingStore, ['fetchShippingMethods', 'setCustomer', 'setAddress']),
    ...mapActions(useCartStore, ['setShippingMethod']),
    ...mapActions(useMainStore, ['setPaymentButtonInitialised']),

    async init() {
      try {
        const parent = this;
        const storeCurrency = this.currency;

        const clientInstance = await this.braintreeApi.client.create({
          authorization: this.transaction.token,
        });

        if (!isBraintreePaymentAvailable(clientInstance, 'paypal')) {
          this.setPaymentButtonInitialised(this.paymentProvider, 'paypal', 'NOT_ENABLED');
          this.isRenderComponent = false;
          return;
        }

        this.braintreeApi.paypalCheckout.create(
          {
            client: clientInstance,
          },
          (paypalCheckoutErr, paypalCheckoutInstance) => {
            this.paypalApi.Button.render(
              {
                env: 'sandbox',
                fundingSource: this.paypalApi.FUNDING.PAYPAL,
                intent: 'authorize',
                commit: true,
                style: {
                  color: 'gold',
                  shape: 'rect',
                  height: 50,
                  tagline: false,
                },

                payment() {
                  // Else render modal
                  return paypalCheckoutInstance.createPayment({
                    flow: 'checkout',
                    amount: parent.getTotalCartPrice,
                    currency: storeCurrency.currencyCode,
                    enableShippingAddress: true,
                    shippingOptions: [],
                  });
                },

                async onShippingChange(data, actions) {
                  const newShippingOptions = [];
                  const shippingOptionData = data.selected_shipping_option;

                  // Set selected shipping Option
                  parent.shippingOption = shippingOptionData || null;

                  const address = {
                    streetAddress: [''],
                    city: data.shipping_address.city,
                    region: data.shipping_address.state,
                    postalCode: data.shipping_address.postal_code,
                    country: data.shipping_address.country_code,
                  };

                  parent.setAddress('billing', address);
                  parent.setAddress('shipping', address);

                  await parent.fetchShippingMethods();

                  (parent.shippingMethods || []).forEach((method, key) => {
                    // If there is not already a shipping method set then lets set one!
                    if (key === 0 && !shippingOptionData) {
                      parent.setShippingOption(method);
                    }

                    // Create array of methods in the required format
                    newShippingOptions.push({
                      id: method.code,
                      type: 'SHIPPING',
                      label: method.label,
                      selected: parent.shippingOption.id === method.code,
                      amount: {
                        value: method.amount,
                        currency_code: storeCurrency.currencyCode,
                      },
                    });
                  });

                  // Set the method in the app
                  await parent.setShippingMethod(parent.shippingOption.id);

                  // Once everything is calculated then set the shipping options and updated price in paypal window
                  return actions.order.patch([
                    {
                      op: !shippingOptionData ? 'add' : 'replace',
                      path: "/purchase_units/@reference_id=='default'/shipping/options",
                      value: newShippingOptions,
                    },
                    {
                      op: 'replace',
                      path: "/purchase_units/@reference_id=='default'/amount",
                      value: {
                        currency_code: storeCurrency.currencyCode,
                        value: parseFloat(parent.getTotalPaymentPrice, 10),
                        breakdown: {
                          item_total: {
                            currency_code: storeCurrency.currencyCode,
                            value: parseFloat(parent.getTotalCartPrice, 10),
                          },
                          shipping: {
                            currency_code: storeCurrency.currencyCode, // DS parent.shippingOption.amount.currency_code,
                            value: parseFloat(parent.shippingOption.amount.value, 10),
                          },
                        },
                      },
                    },
                  ]);
                },

                /**
                 * On authorization then handle payload and place order
                 */
                onAuthorize(paymentData) {
                  return paypalCheckoutInstance.tokenizePayment(paymentData, (err, payload) => {
                    parent.handlePayPalPayload(payload); // Submit `payload.nonce`

                    gtmTracker.doPlaceOrderGtmEvent({
                      paymentType: 'PayPal',
                    });
                  });
                },
              },
              '#paypal-button',
            );
          },
        );

        this.setPaymentButtonInitialised(this.paymentProvider, 'paypal', 'INITIALISED');
      } catch (err) {
        throw new PayPalPaymentError('Error in init()!', err, this.paymentProvider);
      }
    },

    setShippingOption(method) {
      try {
        this.shippingOption = {
          id: method.code,
          type: 'SHIPPING',
          label: method.label,
          selected: true,
          amount: {
            value: method.amount,
            currency_code: this.currency.currencyCode,
          },
        };
      } catch (err) {
        throw new PayPalPaymentError('Error in setShippingOption()!', err, this.paymentProvider);
      }
    },

    // @see https://braintree.github.io/braintree-web/current/PayPalCheckout.html#~tokenizePayload
    async handlePayPalPayload(data) {
      try {
        const shippingAddress = {
          streetAddress: [
            data.details.shippingAddress.line1,
            data.details.shippingAddress.line2 ?? '',
          ],
          city: data.details.shippingAddress.city,
          postalCode: data.details.shippingAddress.postalCode,
          region: data.details.shippingAddress.state,
          country: data.details.shippingAddress.countryCode,
        };

        const billingAddress = {
          streetAddress: [
            data.details.billingAddress.line1,
            data.details.billingAddress.line2 ?? '',
          ],
          city: data.details.billingAddress.city,
          postalCode: data.details.billingAddress.postalCode,
          region: data.details.billingAddress.state,
          country: data.details.billingAddress.countryCode,
        };

        // Workaround for when PayPal response doesn't include full Billing Address details
        if (!billingAddress.streetAddress[0]?.length) {
          billingAddress.streetAddress[0] = 'The Street';
        }
        if (!billingAddress.city) {
          billingAddress.city = 'The City';
        }
        if (!billingAddress.postalCode) {
          billingAddress.postalCode = 'BN1 1AB';
        }
        if (!billingAddress.region) {
          billingAddress.region = 'Lapland';
        }

        this.setCustomer('shipping', {
          firstName: data.details.shippingAddress.recipientName,
          lastName: data.details.shippingAddress.recipientName,
        });

        this.setCustomer('billing', {
          firstName: data.details.firstName,
          lastName: data.details.lastName,
          phone: data.details.phone,
          email: data.details.email,
        });

        this.setAddress('shipping', shippingAddress);
        this.setAddress('billing', billingAddress);

        // Set payment nonce
        this.setBraintreePaymentData({
          nonce: data.nonce,
          method: 'paypal',
        });

        await this.placeBraintreeOrder();
      } catch (err) {
        throw new PayPalPaymentError('Error in handlePayPalPayload()!', err, this.paymentProvider);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
